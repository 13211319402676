import React from "react";

import { theme, H1, P } from "../../App.styles";
import Styles from "./JumbotronWithTextAndImage.styles";

import developerImage1 from "../../assets/images/developer/developer-coding-image-1.png";
import developerImage2 from "../../assets/images/developer/developer-coding-image-2.png";
import developerImage3 from "../../assets/images/developer/developer-coding-image-3.png";

const JumbotronWithTextAndImage = () => {
    return (
        <Styles>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <H1 className="mb-5" color={theme.colorWhite}>
                            We develop a web presence
                        </H1>
                        <P subtitle color={theme.colorWhite}>
                            At Pinecone Software, we help you make new customer engagements by extending your online
                            existence.
                        </P>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="s1_images">
                            <img
                                className="s1_images--image1"
                                src={developerImage1}
                                alt="Developer sitting in front of computers without light"
                            />
                            <img
                                className="s1_images--image2"
                                src={developerImage2}
                                alt="The illustration of a light beam above the developer's desk"
                            />
                            <img
                                className="s1_images--image3"
                                src={developerImage3}
                                alt="The illustration of a shadow over the developer's computers"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Styles>
    );
};

export default JumbotronWithTextAndImage;
