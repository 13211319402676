import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Styles from "./Contact.styles";
import { theme } from "../../App.styles";
import Navbar from "../../components/navbar/Navbar";
import JumbotronWithOnlyText from "../../components/jumbotronWithOnlyText/JumbotronWithOnlyText";
import ContactForm from "./ContactForm";
import Footer from "../../components/footer/Footer";

const Contact = () => {
    const location = useLocation();

    const metaContent = {
        title: "Contact - Pinecone Software Limited",
        description:
            "At Pinecone Software, we help you make new customer engagements by extending your online existence. Get in touch and tell us about your web service need. We will get it sorted!",
        canonical: `https://pineconesoftware.co.uk${location.pathname}`
    };

    return (
        <>
            {/****** Page level head tag content ******/}
            <Helmet>
                <title>{metaContent.title}</title>
                <meta property="og:title" content={metaContent.title} />
                <meta property="og:description" content={metaContent.description} />
                <link rel="canonical" href={metaContent.canonical} />
            </Helmet>

            {/****** Page content ******/}
            <Styles>
                <header>
                    <div className="backgroundWobble"></div>
                    <Navbar />
                    <JumbotronWithOnlyText
                        title="Contact"
                        subtitle="Tell us about your web service need, and we will get it sorted!"
                    />
                </header>
                <main>
                    <ContactForm />
                </main>
                <Footer color={theme.colorLightGray} />
            </Styles>
        </>
    );
};

export default Contact;
