import styled from "styled-components";

const Styles = styled.section`
    padding: 75px 0;

    @media (max-width: 768px) {
        padding: 45px 0;
    }

    .contact {
        &_form {
            &--item {
                display: flex;
                flex-flow: column nowrap;
                margin-bottom: 24px;

                label {
                    font-size: 22px;
                    font-family: "GilroyExtraBold";
                    line-height: 26px;
                    color: ${(props) => props.theme.colorLightBlue};
                }

                input[type="text"],
                textarea {
                    width: 100%;
                    border: 1px solid ${(props) => props.theme.colorLogoBlue25};
                    border-radius: 8px;
                    padding: 8px;
                }

                input[type="text"] {
                    height: 48px;
                }

                &--error {
                    color: ${(props) => props.theme.colorError};
                    margin-top: 8px;
                }
            }

            &--submit {
                display: flex;

                @media (max-width: 991.98px) {
                    justify-content: center;
                }

                &--button {
                    outline: 0;
                    border: 0;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background: ${(props) => props.theme.colorLightBlue};
                    background: linear-gradient(
                        90deg,
                        ${(props) => props.theme.colorLightBlue} 45%,
                        ${(props) => props.theme.colorLogoBrown} 100%
                    );
                    font-family: "GilroyExtraBold";
                    font-size: 26px;
                    line-height: 32px;
                    color: ${(props) => props.theme.colorWhite};
                    text-transform: capitalize;
                    padding: 24px 56px;
                    border-radius: 100px;

                    &:hover {
                        text-decoration: none;

                        .contact_form--submit--text {
                            margin-right: 16px;
                        }
                    }
                }

                &--text {
                    transition: all 0.2s ease-in;
                    margin-right: 8px;
                }
            }
        }

        &_image {
            @media (max-width: 991.98px) {
                display: none;
            }

            &--image {
                width: 100%;
            }

            &--caption {
                text-align: center;

                a {
                    color: ${(props) => props.theme.colorBase};
                }
            }
        }
    }
`;

export default Styles;
