import GilRoyExtraBold from "./Gilroy-ExtraBold.otf";
import MuliRegular from "./Muli-Regular.ttf";
import MuliLight from "./Muli-Light.ttf";

const fonts = `
    @font-face {
        font-family: 'GilRoyExtraBold';
        src: local('GilRoyExtraBold'), local('GilRoyExtraBold'),
        url(${GilRoyExtraBold}) format('opentype');
        font-style: normal;
    }

    @font-face {
        font-family: 'MuliRegular';
        src: local('MuliRegular'), local('MuliRegular'),
        url(${MuliRegular}) format('truetype');
        font-style: normal;
    }

    @font-face {
        font-family: 'MuliLight';
        src: local('MuliLight'), local('MuliLight'),
        url(${MuliLight}) format('truetype');
        font-style: normal;
    }
`;

export default fonts;

// import { createGlobalStyle } from 'styled-components';

// import GilRoyExtraBold from './Gilroy-ExtraBold.otf';
// import MuliRegular from './Muli-Regular.ttf';
// import MuliLight from './Muli-Light.ttf';

// export default createGlobalStyle`
//     @font-face {
//         font-family: 'GilRoyExtraBold';
//         src: local('GilRoyExtraBold'), local('GilRoyExtraBold'),
//         url(${GilRoyExtraBold}) format('opentype');
//         font-style: normal;
//     }

//     @font-face {
//         font-family: 'MuliRegular';
//         src: local('MuliRegular'), local('MuliRegular'),
//         url(${MuliRegular}) format('truetype');
//         font-style: normal;
//     }

//     @font-face {
//         font-family: 'MuliLight';
//         src: local('MuliLight'), local('MuliLight'),
//         url(${MuliLight}) format('truetype');
//         font-style: normal;
//     }
// `;
