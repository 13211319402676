import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import Styles from "./About.styles";
import { theme } from "../../App.styles";
import Navbar from "../../components/navbar/Navbar";
import JumbotronWithOnlyText from "../../components/jumbotronWithOnlyText/JumbotronWithOnlyText";
import AboutText from "./AboutText";
import Footer from "../../components/footer/Footer";

const About = () => {
    const location = useLocation();

    const metaContent = {
        title: "About - Pinecone Software Limited",
        description:
            "Pinecone Software Limited is a next-generation web development service company. We are founded with the motivation of providing a complete cycle of web services.",
        canonical: `https://pineconesoftware.co.uk${location.pathname}`
    };

    return (
        <>
            {/****** Page level head tag content ******/}
            <Helmet>
                <title>{metaContent.title}</title>
                <meta property="og:title" content={metaContent.title} />
                <meta property="og:description" content={metaContent.description} />
                <link rel="canonical" href={metaContent.canonical} />
            </Helmet>

            {/****** Page content ******/}
            <Styles>
                <header>
                    <div className="backgroundWobble"></div>
                    <Navbar />
                    <JumbotronWithOnlyText
                        title="About"
                        subtitle="We are founded with the motivation of providing a complete cycle of web services"
                    />
                </header>
                <main>
                    <AboutText />
                </main>
                <Footer color={theme.colorLightGray} />
            </Styles>
        </>
    );
};

export default About;
