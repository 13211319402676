import styled from "styled-components";

const Styles = styled.section`
    padding: 75px 0;

    @media (max-width: 768px) {
        padding: 45px 0;
    }

    .s4 {
        &_main {
            text-align: center;
            margin-bottom: 48px;
        }

        &_list {
            &--1 {
                margin-right: 40px;
                margin-bottom: 0;
                text-align: right;

                @media (max-width: 1199.98px) {
                    text-align: left;
                    margin-right: 0;
                }
            }

            &--2 {
                margin-left: 40px;
                margin-bottom: 0;

                @media (max-width: 1199.98px) {
                    margin-left: 0;
                }
            }

            &--item {
                display: flex;
                flex-flow: row nowrap;
                margin-bottom: 56px;

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                @media (max-width: 1199.98px) {
                    margin-bottom: 32px;
                }

                &--icon {
                    svg {
                        height: 32px;
                        width: 32px;
                    }

                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 72px;
                    padding: 16px;
                    background-color: ${(props) => props.theme.colorLogoBlue};
                    border-radius: 64% 36% 74% 26% / 74% 68% 32% 26%;
                    margin-right: 24px;

                    @media (min-width: 1200px) {
                        display: none;
                    }
                }
            }
        }

        &_images {
            position: relative;
            margin: auto;

            @media (min-width: 768px) and (max-width: 1199.98px) {
                margin: 32px auto;
                width: 55%;
            }

            @media (max-width: 767.98px) {
                margin: 32px auto;
                width: 85%;
            }

            &--road {
                width: 100%;
            }

            &--pin1 {
                position: absolute;
                top: 5%;
                left: 35%;
                animation: breathe 2s ease-in-out infinite;
            }

            &--pin2 {
                position: absolute;
                top: 15%;
                left: 82%;
                animation: breathe 2.2s ease-in-out infinite;
            }

            &--pin3 {
                position: absolute;
                top: 55%;
                left: 8%;
                animation: breathe 2.4s ease-in-out infinite;
            }

            @keyframes breathe {
                50% {
                    transform: translateY(-30px);
                }
            }
        }
    }
`;

export default Styles;
