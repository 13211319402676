import styled from "styled-components";

const Styles = styled.section`
    padding: 75px 0;
    text-align: center;

    @media (max-width: 768px) {
        padding: 45px 0;
    }

    .s2 {
        &_main {
            margin-bottom: 48px;
        }

        &_card {
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            height: 100%;
            padding: 0 25px;

            &::after {
                content: "";
                position: absolute;
                width: 60px;
                bottom: 0;
                margin: 0 auto;
                border-bottom: 4px solid rgba(132, 79, 41, 0.1);
            }

            @media (max-width: 1199.98px) {
                height: auto;
                padding: 0;
                margin-bottom: 24px;
            }

            &--header {
                display: flex;
                flex-flow: column nowrap;
                width: 100%;
                margin-bottom: 24px;

                @media (max-width: 1199.98px) {
                    flex-flow: row nowrap;
                    align-items: center;
                    margin-bottom: 12px;
                }

                &--icon {
                    width: 150px;
                    height: 150px;
                    margin: 0 auto 24px;

                    @media (max-width: 1199.98px) {
                        width: 100px;
                        height: 100px;
                        margin: 0;
                    }
                }

                &--title {
                    @media (max-width: 1199.98px) {
                        text-align: left;
                        margin: 0 0 0 16px;
                    }
                }
            }

            &--body {
                margin-bottom: 40px;

                @media (max-width: 1199.98px) {
                    height: auto;
                    text-align: left;
                    margin-bottom: 16px;
                }
            }
        }
    }
`;

export default Styles;
