import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { theme, P, H2, Curve } from "../../App.styles";
import Styles from "./Section3.styles";

import WinnerImage1 from "../../assets/images/winner/high-search-ranks-image-1.png";
import WinnerImage2 from "../../assets/images/winner/high-search-ranks-image-2.png";
import WinnerImage3 from "../../assets/images/winner/high-search-ranks-image-3.png";

import Button from "../button/Button";

const Section3 = ({ color }) => {
    const section3ListContent = [
        {
            icon: "layer-group",
            text: "This practice of optimizing content consists of many parameters. From page speed to domain authority, your site must meet many criteria to be discovered. Sounds overwhelming? You are covered."
        },
        {
            icon: "random",
            text: "Google has shifted to mobile-first indexing in 2018, because internet users mainly surf from mobile devices. In other words, this is why everyone talks about mobile-first design."
        },
        {
            icon: "medal",
            text: "At Pinecone Software, our focus is way beyond developing a fancy website. We work very hard to equip your website with all measures of SEO, and advise you for your business's digital journey."
        }
    ];

    return (
        <Styles color={color}>
            <Curve top color={color} />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="s3_images">
                            <img
                                className="s3_images--image1"
                                src={WinnerImage1}
                                alt="Spotlights pointing the winner"
                            />
                            <img
                                className="s3_images--image2"
                                src={WinnerImage2}
                                alt="Middle spotlignt that flashes on the winner"
                            />
                            <img
                                className="s3_images--image3"
                                src={WinnerImage3}
                                alt="Placeholder that represents the winner"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="s3_main">
                            <H2 color={theme.colorWhite}>Drive More Traffic!</H2>
                            <P subtitle color={theme.colorWhite}>
                                SEO (Search engine optimization) is vital. If you wish to receive (more) traffic and
                                gain higher ranks over search engine results, SEO must not be overlooked.
                            </P>
                        </div>
                        <ul className="s3_list list-unstyled">
                            {section3ListContent.map((content, index) => {
                                return (
                                    <li className="s3_list--item" key={index}>
                                        <span className="s3_list--item--icon">
                                            <FontAwesomeIcon icon={content.icon} size="2x" color="white" />
                                        </span>
                                        <P color={theme.colorWhite}>{content.text}</P>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <Button url="/contact" text="Contact us" />
                </div>
            </div>
            <Curve bottom color={color} />
        </Styles>
    );
};

export default Section3;
