import React from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import Styles from "./ContactForm.styles";
import { theme } from "../../App.styles";
import mailbox from "../../assets/images/vintage-mailbox-illustration.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactForm = () => {
    // https://codesandbox.io/s/peaceful-stonebraker-308hg?from-embed

    const { register, handleSubmit, errors } = useForm();

    const encode = (data) => {
        return Object.keys(data)
            .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    };

    const recaptchaRef = React.createRef();

    const handleFormSubmit = (e) => {
        e.preventDefault();
        recaptchaRef.current.execute();
    };

    // TODO:
    // eslint-disable-next-line no-unused-vars
    const completed = (value) => {
        // console.log(value);
        handleSubmit((data) => {
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact", ...data })
            })
                .then(() => alert("Success!"))
                .catch((error) => alert(error));
        })();
    };

    return (
        <Styles>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-12 contact_form">
                        <form action="/response" onSubmit={handleFormSubmit}>
                            <input type="hidden" name="form-name" value="contact" />
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6LdTs6cZAAAAAH-i_Z_oTWzfli8bbLK1Xat4uUnC"
                                onChange={(value) => completed(value)}
                            />
                            <div className="contact_form--item">
                                <label htmlFor="fullName">Full Name</label>
                                <input
                                    id="fullName"
                                    type="text"
                                    name="fullName"
                                    ref={register({
                                        required: "Please provide us your full name",
                                        minLength: {
                                            value: 2,
                                            message: "The minimum length is 2"
                                        }
                                    })}
                                />
                                {errors.fullName && (
                                    <p className="contact_form--item--error">{errors.fullName.message}</p>
                                )}
                            </div>
                            <div className="contact_form--item">
                                <label htmlFor="email">Your email address</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="text"
                                    ref={register({
                                        required: "Please provide us your email address",
                                        pattern: {
                                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            message: "Invalid email address"
                                        }
                                    })}
                                />
                                {errors.email && <p className="contact_form--item--error">{errors.email.message}</p>}
                            </div>
                            <div className="contact_form--item">
                                <label htmlFor="request">What can we help you with?</label>
                                <textarea
                                    id="request"
                                    name="request"
                                    rows="6"
                                    ref={register({
                                        required: "Please provide us your web development need",
                                        minLength: {
                                            value: 5,
                                            message: "The minimum length is 5"
                                        }
                                    })}
                                />
                                {errors.request && (
                                    <p className="contact_form--item--error">{errors.request.message}</p>
                                )}
                            </div>
                            <div className="contact_form--submit">
                                <button className="contact_form--submit--button" type="submit">
                                    <span className="contact_form--submit--text">Send</span>
                                    <FontAwesomeIcon icon="paper-plane" color={theme.colorWhite} />
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-5 col-md-12 contact_image">
                        <figure>
                            <img className="contact_image--image" src={mailbox} alt="Vintage mailbox illustration" />
                            <figcaption className="contact_image--caption">
                                <small>
                                    <a
                                        href="https://www.freepik.com/free-photos-vectors/vintage"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Image: Vintage vector created by freepik
                                    </a>
                                </small>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </Styles>
    );
};

export default ContactForm;
