import React from "react";

import { P, H2, H3 } from "../../App.styles";
import Styles from "./Section2.styles";

import consultancyIcon from "../../assets/images/consultancy-icon.png";
import developmentIcon from "../../assets/images/development-icon.png";
import maintenanceIcon from "../../assets/images/maintenance-icon.png";

const Section2 = () => {
    return (
        <Styles>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 s2_main">
                        <H2>Introducing A to Z Web Services</H2>
                        <P subtitle>
                            Whether you need web consultancy, complete/partial development, or maintenance, our company
                            has a wide range of services.
                        </P>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-12">
                        <div className="s2_card">
                            <div className="s2_card--header">
                                <img
                                    className="s2_card--header--icon"
                                    src={consultancyIcon}
                                    alt="Consultancy icon"
                                ></img>
                                <H3 className="s2_card--header--title">Web Consultancy</H3>
                            </div>
                            <P className="s2_card--body">
                                This unique service is designed to unlock the potential of your website. From SEO
                                strategy to deep technical analysis, we offer various services to enhance your web
                                presence. If you are not a website owner (yet), consider this service as a discovery of
                                your needs and a tailor-made roadmap; so you would know what to expect before giving
                                your final decision!
                            </P>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-12">
                        <div className="s2_card">
                            <div className="s2_card--header">
                                <img
                                    className="s2_card--header--icon"
                                    src={developmentIcon}
                                    alt="Development icon"
                                ></img>
                                <H3 className="s2_card--header--title">Development</H3>
                            </div>
                            <P className="s2_card--body">
                                Do you want your website to be done from start to finish? Do you have a website but
                                needs to be worked on? Do you have your designs ready and looking for someone to turn
                                them into a website? Do you need an additional piece of software that would work along
                                with your website? If any of these questions roughly explain your need, then contact us!
                            </P>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-12">
                        <div className="s2_card">
                            <div className="s2_card--header">
                                <img
                                    className="s2_card--header--icon"
                                    src={maintenanceIcon}
                                    alt="Maintenance icon"
                                ></img>
                                <H3 className="s2_card--header--title">Maintenance</H3>
                            </div>
                            <P className="s2_card--body">
                                Your website needs to be taken care of regularly! It is common to neglect to show the
                                necessary care to your website. While maintenance could address a failed part of your
                                website, it could also mean a brand new look or just a bit of polishing. Always keep in
                                mind that, your site seems to be working flawlessly to you, but the majority of your
                                customers may not agree with it.
                            </P>
                        </div>
                    </div>
                </div>
            </div>
        </Styles>
    );
};

export default Section2;
