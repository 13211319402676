import React, { useState, useEffect } from "react";
import { useResolvedPath, Link, useMatch } from "react-router-dom";
import cx from "classnames";
import NavbarLogo from "../../assets/images/pinecone-software-limited-navbar-logo.png";
import Styles from "./Navbar.styles";

const Navbar = () => {
    const [mobileMenuState, setMobileMenuState] = useState(false);

    const toggleMenu = () => {
        setMobileMenuState(!mobileMenuState);
    };

    useEffect(() => {
        if (mobileMenuState) {
            document.body.classList.add("kill-body-scroll");
        } else {
            document.body.classList = "";
        }
    });

    const navLinks = [
        {
            path: "/",
            name: "Home"
        },
        {
            path: "/about",
            name: "About"
        },
        {
            path: "/contact",
            name: "Contact"
        }
    ];

    const CustomLink = ({ children, to, className, ...props }) => {
        let resolved = useResolvedPath(to);
        let match = useMatch({ path: resolved.pathname, end: true });

        return (
            <>
                <Link to={to} className={cx(className, match && "active")} {...props}>
                    {children}
                </Link>
                {match && <span className="sr-only">(current)</span>}
            </>
        );
    };

    return (
        <Styles>
            {mobileMenuState && (
                <>
                    <div className="mobileMenu-wrapper"></div>
                    <div className="mobileMenu-linksContainer">
                        {navLinks.map((link, idx) => {
                            return (
                                <CustomLink key={idx} to={link.path} className="mobileMenu-links">
                                    {link.name}
                                </CustomLink>
                            );
                        })}
                    </div>
                </>
            )}
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={NavbarLogo} alt="Pinecone Software Limited Logo Header Small" />
                    </Link>
                    <button
                        type="button"
                        className={`navbar-toggler ${mobileMenuState ? "menuCloseIcon" : ""}`}
                        onClick={toggleMenu}
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <div className="line1"></div>
                        <div className="line2"></div>
                        <div className="line3"></div>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            {navLinks.map((link) => {
                                return (
                                    <li key={link.name.toString()} className="nav-item">
                                        <CustomLink to={link.path} className="nav-link">
                                            {link.name}
                                        </CustomLink>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </nav>
        </Styles>
    );
};

export default Navbar;
