import styled, { css } from "styled-components";
import { P } from "../../App.styles";

const Styles = styled.section`
    background-color: ${(props) => (props.color ? props.color : "white")};
    /* margin-top: 75px; */
    padding-bottom: 75px;

    @media (max-width: 768px) {
        /* margin-top: 45px; */
        padding-bottom: 45px;
    }

    .s5 {
        &_main {
            text-align: center;
            margin-bottom: 48px;
        }

        &_card {
            display: flex;
            flex-flow: column nowrap;
            text-align: left;
            background-color: ${(props) => props.theme.colorWhite};
            padding: 48px 32px;

            @media (max-width: 768px) {
                height: auto;
            }

            &--header {
                display: flex;
                flex-flow: column nowrap;

                @media (min-width: 768px) and (max-width: 991.98px) {
                    flex-flow: row nowrap;
                    align-items: center;
                    margin-bottom: 16px;
                }

                &--logo {
                    width: 85px;
                    height: 85px;
                    margin-bottom: 24px;

                    @media (min-width: 768px) and (max-width: 991.98px) {
                        margin: 0 16px 0 0;
                    }
                }

                &--title {
                    height: 64px; // because title could be two lines. (line-height * 2)
                }
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
                padding: 32px;
                margin-bottom: 32px;
            }

            @media (max-width: 767.98px) {
                margin-bottom: 32px;
                border-top: 12px solid ${(props) => props.theme.colorLogoBlue};
                border-right: 12px solid ${(props) => props.theme.colorWhite};
            }
        }
    }
`;

const ReferenceSummaryText = styled(P)`
    ${({ isShowMore }) =>
        isShowMore &&
        css`
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
        `};
`;

const ShowMoreShowLessButton = styled.span`
    display: inline-flex;
    margin-left: auto;
    padding: 4px;
    font-style: italic;
    color: ${({ theme }) => theme.colorLightBlue};
    cursor: pointer;
`;

export { Styles, ReferenceSummaryText, ShowMoreShowLessButton };
