import styled from "styled-components";

const Styles = styled.section`
    padding: 75px 0;

    @media (max-width: 768px) {
        padding: 45px 0;
    }
`;

export default Styles;
