import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/navbar/Navbar";
import JumbotronWithOnlyText from "../../components/jumbotronWithOnlyText/JumbotronWithOnlyText";

const NotFoundPage = () => {
    return (
        <>
            {/****** Page level head tag content ******/}
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <meta name="googlebot" content="noindex" />
            </Helmet>
            {/****** Page content ******/}
            <header>
                <div className="backgroundWobble"></div>
                <Navbar />
                <JumbotronWithOnlyText title="404" subtitle="We couldn't find the page you are looking for." />
            </header>
        </>
    );
};

export default NotFoundPage;
