import styled from "styled-components";

const Styles = styled.div`
    .line1,
    .line2,
    .line3 {
        width: 24px;
        height: 2px;
        background-color: ${(props) => props.theme.colorWhite};
        margin: 6px 0;
        transition: 0.5s;
    }

    .line1,
    .line3 {
        margin-left: 6px;
    }

    .menuCloseIcon {
        .line1 {
            transform: rotate(-45deg) translate(-5px, 6px);
        }

        .line2 {
            opacity: 0;
        }

        .line3 {
            transform: rotate(45deg) translate(-5px, -7px);
        }
    }

    .mobileMenu {
        &-wrapper {
            display: flex;
            position: fixed;
            top: 0px;
            right: 0px;
            border-radius: 100% 0% 60% 40% / 0% 49% 51% 100%;
            width: 10px;
            height: 10px;
            z-index: 99;
            background-color: ${(props) => props.theme.colorBlack85};
            animation: mobileBackgroundCoverAnim 1s ease-in-out forwards;

            @keyframes mobileBackgroundCoverAnim {
                100% {
                    width: 500%;
                    height: 200%;
                    right: -50%;
                }
            }
        }

        &-linksContainer {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            z-index: 999;
            transform: translateY(-20px);
            opacity: 0;
            animation: mobileMenuLinksAnim 1s 0.2s ease-in-out forwards;

            @keyframes mobileMenuLinksAnim {
                100% {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        &-links {
            font-family: "GilroyExtraBold";
            font-size: 55px;
            line-height: 55px;
            padding: 16px;
            color: ${(props) => props.theme.colorWhite};
        }
    }

    .navbar {
        padding: 36px 0 0 0;
        z-index: 999;

        @media (max-width: 768px) {
            padding: 1rem;
        }

        .navbar-brand {
            margin-right: 48px;

            img {
                width: 50px;

                @media (max-width: 768px) {
                    width: 35px;
                }
            }
        }

        .navbar-toggler {
            border: 0;
            padding: 0;

            &:focus {
                outline: 0;
            }
        }

        .navbar-nav {
            .nav-item {
                margin-right: 40px;

                @media (max-width: 768px) {
                    margin: 0;
                }

                .nav-link {
                    font-size: 22px;
                    line-height: 36px;
                    color: ${(props) => props.theme.colorWhite};

                    &.active {
                        border-bottom: 2px solid ${(props) => props.theme.colorWhite};
                    }

                    &:active,
                    &:focus,
                    &:hover {
                        color: ${(props) => props.theme.colorWhiteDirty};
                    }
                }
            }
        }
    }
`;

export default Styles;
