import styled from "styled-components";

const Styles = styled.section`
    padding: 150px 0 200px;

    @media (min-width: 992px) and (max-width: 1199.98px) {
        padding: 100px 0 150px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: 75px 0 125px;
    }

    @media (max-width: 767.98px) {
        padding: 50px 0 100px;
    }

    .s1 {
        &_images {
            position: absolute;
            top: -171px;
            right: 0;
            width: 75%;

            @media (min-width: 992px) and (max-width: 1199.98px) {
                position: relative;
                margin-left: auto;
                top: 0px;
            }

            @media (max-width: 991.98px) {
                position: relative;
                top: 48px;
                margin: 0 auto;
                width: 85%;
            }

            &--image1 {
                position: relative;
                width: 100%;
            }

            &--image2 {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 1;
                animation: light 8s infinite;
            }

            &--image3 {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                animation: shadow 8s infinite;
            }

            @keyframes light {
                50% {
                    opacity: 0;
                }
            }

            @keyframes shadow {
                50% {
                    opacity: 1;
                }
            }
        }
    }
`;

export default Styles;
