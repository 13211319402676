import styled from "styled-components";

const Styles = styled.footer`
    background-color: ${(props) => (props.color ? props.color : props.theme.colorWhite)};

    .footer {
        &__logo {
            margin-bottom: 32px;

            img {
                width: 270px;

                @media (max-width: 767.98px) {
                    width: 50%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &__contact {
            word-break: break-word;

            @media (max-width: 767.98px) {
                margin-top: 24px;
            }

            ul {
                margin-bottom: 0;
                display: flex;
                flex-flow: column nowrap;

                li {
                    position: relative;
                    margin-left: 40px;

                    .footerIcon {
                        left: -40px;
                        position: absolute;
                    }

                    &:not(:last-of-type) {
                        margin-bottom: 8px;
                    }
                }
            }
        }

        &__bottom {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding: 20px 0;
            border-top: 1px solid rgba(95, 95, 95, 0.15);
            margin-top: 32px;

            @media (max-width: 767.98px) {
                border-top: 0;
                padding: 0;
                margin: 24px 0 8px 0;
            }

            &--copyright {
                @media (max-width: 767.98px) {
                    order: 2;
                }

                &--text {
                    font-size: 16px;
                    line-height: 24px;

                    @media (max-width: 767.98px) {
                        line-height: 22px;
                    }
                }
            }

            &--navigation {
                margin-bottom: 0;
                display: flex;
                align-items: center;

                li {
                    a {
                        display: inline-flex;
                        padding: 8px 0;

                        &.active {
                            font-weight: bold;
                        }
                    }
                }

                @media (max-width: 767.98px) {
                    margin-bottom: 8px;
                    border: 1px solid rgba(95, 95, 95, 0.15);
                    border-width: 1px 0 1px 0;
                    order: 1;
                }
            }

            @media (max-width: 767.98px) {
                flex-flow: column nowrap;
            }

            &--flag {
                display: flex;
                align-items: center;
                margin-left: 16px;

                img {
                    width: 25px;
                    height: 25px;
                    margin-right: 4px;
                }
            }
        }

        &__bottom,
        &__contact {
            a,
            p {
                color: ${(props) => props.theme.colorBase};
            }
        }
    }
`;

export default Styles;
