import React from "react";

import { theme, H1, P } from "../../App.styles";
import Styles from "./JumbotronWithOnlyText.styles";

const JumbotronWithOnlyText = ({ title, subtitle }) => {
    return (
        <Styles>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 jwot">
                        <H1 className="mb-4" color={theme.colorWhite}>
                            {title}
                        </H1>
                        <P subtitle color={theme.colorWhite}>
                            {subtitle}
                        </P>
                    </div>
                </div>
            </div>
        </Styles>
    );
};

export default JumbotronWithOnlyText;
