import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Styles from "./Section4.styles";
import { P, H2, H3 } from "../../App.styles";

import Road from "../../assets/images/roadmap/road.png";
import GreenPin from "../../assets/images/roadmap/location-pin-green.png";
import YellowPin from "../../assets/images/roadmap/location-pin-yellow.png";
import BluePin from "../../assets/images/roadmap/location-pin-blue.png";

const Section4 = () => {
    const section4projectSteps = [
        {
            icon: "code",
            name: "Information Phase",
            text: "Discussion about the main goal, the scope, and the potential audience. Decide on pages and features to fulfill the goal."
        },
        {
            icon: "clipboard",
            name: "Planning",
            text: "Sitemap & wireframe creation and timeline planning based on the information gathered in the first phase."
        },
        {
            icon: "paint-brush",
            name: "Content & Design",
            text: "All the visual content and page layouts that demonstrates the end product. Clients get to review and approve."
        },
        {
            icon: "code",
            name: "Coding",
            text: "This is where the magic happens! It is time to introduce all the above work to various development components."
        },
        {
            icon: "rocket",
            name: "Test, Review & Launch",
            text: "Finally, it is time to launch! However, let's test & review first so as to make sure that everything works as expected."
        },
        {
            icon: "cog",
            name: "Maintenance",
            text: "This act of regularly checking your site ensures no issues stand in your way to attract and retain customers."
        }
    ];

    return (
        <Styles>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 s4_main">
                        <H2>Behind The Scenes</H2>
                        <P subtitle>
                            We breakdown the web development process into six core steps as to have a successful project
                            flow.
                        </P>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-sm-12">
                        <ul className="s4_list--1 list-unstyled">
                            {section4projectSteps.slice(0, 3).map((step, index) => {
                                return (
                                    <li className="s4_list--item" key={index}>
                                        <div className="s4_list--item--icon">
                                            <FontAwesomeIcon icon={step.icon} color="white" />
                                        </div>
                                        <div>
                                            <H3>{step.name}</H3>
                                            <P>{step.text}</P>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="col-xl-4 d-flex">
                        <div className="s4_images">
                            <img className="s4_images--road" src={Road} alt="Curvy road illustration" />
                            <img className="s4_images--pin1" src={GreenPin} alt="Animated green location pin" />
                            <img className="s4_images--pin2" src={BluePin} alt="Animated blue location pin" />
                            <img className="s4_images--pin3" src={YellowPin} alt="Animated yellow location pin" />
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                        <ul className="s4_list--2 list-unstyled">
                            {section4projectSteps.slice(3, 6).map((step, index) => {
                                return (
                                    <li className="s4_list--item" key={index}>
                                        <div className="s4_list--item--icon">
                                            <FontAwesomeIcon icon={step.icon} color="white" />
                                        </div>
                                        <div>
                                            <H3>{step.name}</H3>
                                            <P>{step.text}</P>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </Styles>
    );
};

export default Section4;
