// Bootstrap grid is imported here
// All the styling except the grid is done using styled-components npm module
import "bootstrap-css-only";
import { createGlobalStyle } from "styled-components";

import fonts from "./assets/fonts/fonts";

import headerBg from "./assets/images/header-background.jpg";

/*
 * Imported font families are:
 *  font-family: 'GilRoyExtraBold';
 *  font-family: 'MuliRegular';
 *  font-family: 'MuliLight';
 */

export default createGlobalStyle`
    ${fonts}

    html, body {
        height: 100%;
    }

    body {
        font-family: 'MuliLight';
    }

    header {
        overflow: hidden;
        position: relative;
    }

    figure {
        margin: 0;
    }

    /* This class is used once the mobile menu is triggered */
    .kill-body-scroll {
        overflow: hidden;
        position: relative;
        height: 100vh;
    }

    .backgroundWobble {
        position: absolute;
        height: 100%;
        top: 0;
        left: -50%;
        right: -50%;
        background-image: url(${headerBg}), linear-gradient(90deg, rgba(133,79,41,1) 50%, rgba(38,42,52,1) 50%);
        background-repeat: no-repeat;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        background-size: 50%;
        background-position: 50% 0%;
        
        @media (max-width: 535px) {
            background-size: contain;
        }
    }
`;
