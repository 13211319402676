/*
 * Defaults:
 *
 * h1 -> font-size: 80px - line-height: 80px - Gilroy Extra Bold
 * h2 -> font-size: 56px - line-height: 64px - Gilroy Extra Bold
 * h3 -> font-size: 26px - line-height: 32px - Gilroy Extra Bold
 * Body P (regular) -> font-size: 22px - line-height: 36px - Muli Regular
 *
 * Font families are imported in App.globals.js, which is the GlobalStyles component
 *
 * 262a34 for super dark blue
 * 854f29 for brown
 *
 * Responsive breakpoint references:
 *
 * // Extra small devices (portrait phones, less than 576px)
 * @media (max-width: 575.98px) { ... }
 *
 * // Small devices (landscape phones, 576px and up)
 * @media (min-width: 576px) and (max-width: 767.98px) { ... }
 *
 * // Medium devices (tablets, 768px and up)
 * @media (min-width: 768px) and (max-width: 991.98px) { ... }
 *
 * // Large devices (desktops, 992px and up)
 * @media (min-width: 992px) and (max-width: 1199.98px) { ... }
 *
 * // Extra large devices (large desktops, 1200px and up)
 * @media (min-width: 1200px) { ... }
 *
 */

import styled, { css } from "styled-components";

export const theme = {
    colorWhite: "#fff",
    colorLightGray: "#F9F9F9",
    colorWhiteDirty: "#c3c1c1",
    colorBase: "#5f5f5f",
    colorLightBlue: "#42495b",
    colorLogoBrown: "#844f29",
    colorLogoBlue: "#282c34",
    colorLogoBlue25: "rgba(40,44,52, .25)",
    colorBlack85: "rgba(0, 0, 0, .85)",
    colorError: "#d12f33"
};

export const H1 = styled.h1`
    font-family: "GilroyExtraBold";
    font-size: 80px;
    line-height: 80px;
    color: ${(props) => (props.color ? props.color : theme.colorBase)};

    @media (max-width: 768px) {
        font-size: 55px;
        line-height: 55px;
    }
`;

export const H2 = styled.h2`
    font-family: "GilroyExtraBold";
    font-size: 56px;
    line-height: 64px;
    color: ${(props) => (props.color ? props.color : theme.colorLightBlue)};
    margin-bottom: 24px;

    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 42px;
    }
`;

export const H3 = styled.h3`
    font-family: "GilroyExtraBold";
    font-size: 26px;
    line-height: 32px;
    color: ${(props) => (props.color ? props.color : theme.colorLightBlue)};

    @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
    }
`;

export const P = styled.p`
    font-size: 20px;
    line-height: 34px;
    font-family: "MuliLight";
    color: ${(props) => (props.color ? props.color : theme.colorBase)};
    margin-bottom: 0;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 32px;
    }

    ${(props) =>
        props.subtitle &&
        css`
            font-size: 24px;
        `}
`;

export const Curve = styled.div`
    background: ${(props) => (props.curveBgColor ? props.curveBgColor : theme.colorWhite)};
    height: 100px;
    overflow: hidden;
    position: relative;

    @media (max-width: 768px) {
        height: 60px;
    }

    &::before {
        content: "";
        background: ${(props) => props.color};
        position: absolute;
        left: -200px;
        right: -200px;
        border-radius: 100%;

        ${(props) =>
            props.top &&
            css`
                top: 0;
                bottom: -200px;
            `}

        ${(props) =>
            props.bottom &&
            css`
                top: -200px;
                bottom: 0;
            `}
    }
`;
