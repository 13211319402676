import styled from "styled-components";

const Styles = styled.div`
    .button {
        &_wrapper {
            display: flex;
            justify-content: center;
            padding-top: 32px;
        }

        &_link {
            display: flex;
            justify-content: center;
            align-items: center;
            background: ${(props) => props.theme.colorLightBlue};
            background: linear-gradient(
                90deg,
                ${(props) => props.theme.colorLightBlue} 45%,
                ${(props) => props.theme.colorLogoBrown} 100%
            );
            font-family: "GilroyExtraBold";
            font-size: 26px;
            line-height: 32x;
            color: ${(props) => props.theme.colorWhite};
            text-transform: capitalize;
            padding: 24px 32px;
            border-radius: 100px;

            &:hover {
                text-decoration: none;

                .button_link--text {
                    margin-right: 16px;
                }
            }

            @media (max-width: 991.98px) {
                padding: 24px 56px;
            }

            &--text {
                transition: all 0.2s ease-in;
                margin-right: 8px;
            }
        }
    }
`;

export default Styles;
