import React from "react";
import Button from "../button/Button";
import Carousel from "react-multi-carousel";
import ForwardPartners from "../../assets/images/clients/forward-partners-client-logo.png";
import MacmillanCancerSupport from "../../assets/images/clients/macmillan-cancer-support-client-logo.png";
// import PlatinAtasehir from "../../assets/images/clients/platin-atasehir-client-logo.png";
import Shelter from "../../assets/images/clients/shelter-client-logo.png";
import Medwise from "../../assets/images/clients/medwise-client-logo.png";
import BreakingWave from "../../assets/images/clients/breaking-wave-client-logo.png";
import "react-multi-carousel/lib/styles.css";
import { theme, P, H2, H3, Curve } from "../../App.styles";
import { Styles, ReferenceSummaryText, ShowMoreShowLessButton } from "./Section5.styles";

const Section5 = ({ color }) => {
    // Create an array state to keep track of which reference items are toggled
    const [isShowMoreArray, setIsShowMoreArray] = React.useState([]);

    const section5clients = [
        {
            logo: ForwardPartners,
            name: "Forward Partners",
            summary:
                "Forward Partners is a London based venture fund that meets startup studio, investing capital, craft, and capability from day zero through Series A. Pinecone Software has provided web development services to Forward Partners team to kick-start their brand new website!"
        },
        {
            logo: MacmillanCancerSupport,
            name: "Macmillan Cancer Support",
            summary:
                "Macmillan Cancer Support is one of the largest British charities and provides specialist health care, information and financial support to people affected by cancer. Pinecone Software is proud of having provided web development services that are being used to fundraise for people who are affected by cancer."
        },
        // {
        //     logo: PlatinAtasehir,
        //     name: "Platin Atasehir",
        //     summary:
        //         "Platin Atasehir is one of the leading real estate company's in Istanbul. Following a flawless web consultation, their amazing team has trusted Pinecone Software to build a ground-up structure that features custom-built powerful CMS and a fresh, dynamic website that attracts many people every day.",
        // },
        {
            logo: Shelter,
            name: "Shelter",
            summary:
                'Shelter helps millions of people every year struggling with bad housing or homelessness. Pinecone Software has provided web development and consultancy services for the purpose of building an "Ending Your Tenancy" digital tool that provides tailored advice on how to end a private rented tenancy based on its users’ specific circumstances.'
        },
        {
            logo: Medwise,
            name: "Medwise",
            summary:
                "Medwise.ai is the Google for medical professionals and healthcare organisations. Pinecone Software has built an MVP by utilising rich features of React, created an isolated UI library based on the brand guidelines, and worked closely with the CEO and the CTO."
        },
        {
            logo: BreakingWave,
            name: "Breaking Wave",
            summary:
                "Breaking Wave is a fintech inside Deutsche Bank. It has been founded to build new digital products for the bank. Pinecone Software has provided software development services to build their mobile-first public-facing website from scratch together with collaborating with the design team regularly. We have also provided software services on internal projects that drive the growth of the company."
        }
    ];

    const showMoreShowLess = React.useCallback(
        (index) => {
            if (!isShowMoreArray.includes(index)) {
                setIsShowMoreArray([...isShowMoreArray, index]);
            } else {
                setIsShowMoreArray([...isShowMoreArray.filter((t) => t !== index)]);
            }
        },
        [isShowMoreArray]
    );

    // Set all reference summary texts to "show less" after changing the slider
    const afterChangeCallback = () => {
        setIsShowMoreArray([]);
    };

    return (
        <Styles color={color}>
            <Curve top color={color} />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 s5_main">
                        <H2 color={theme.colorWhite}>Happy Clients</H2>
                        <P subtitle color={theme.colorWhite}>
                            Pinecone Software has successfully delivered many projects to various sectors.
                        </P>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlaySpeed={1000000} // For some reason, the carousel autoplays after first trigger. (solved via hacky method...)
                            autoPlay={false}
                            centerMode={false}
                            className=""
                            containerClass="container"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            partialVisible
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            afterChange={afterChangeCallback}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1200
                                    },
                                    items: 3,
                                    partialVisibilityGutter: 40
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1200,
                                        min: 768
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 30
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 768,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            {section5clients.map((client, index) => {
                                return (
                                    <div className="s5_card" key={index} style={{ marginRight: 16 }}>
                                        <div className="s5_card--header">
                                            <img
                                                className="s5_card--header--logo"
                                                src={client.logo}
                                                alt={`${client.name} Logo`}
                                            />
                                            <H3 className="s5_card--header--title">{client.name}</H3>
                                        </div>
                                        <ReferenceSummaryText isShowMore={!isShowMoreArray.includes(index)}>
                                            {client.summary}
                                        </ReferenceSummaryText>
                                        <ShowMoreShowLessButton onClick={() => showMoreShowLess(index)}>
                                            {!isShowMoreArray.includes(index) ? "...Show more" : "Show less"}
                                        </ShowMoreShowLessButton>
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                </div>
                <div className="row">
                    <Button url="/contact" text="Get in touch" />
                </div>
            </div>
        </Styles>
    );
};

export default Section5;
