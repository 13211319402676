import styled from "styled-components";

export const Styles = styled.section`
    .backgroundWobble {
        @media (min-width: 991.98px) and (max-width: 1550px) {
            background-size: contain;
        }

        @media (max-width: 991.98px) {
            background-size: cover;
            top: -15%;
            left: -90%;
            right: -90%;
        }
    }
`;

export default Styles;
