import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { theme } from "../../App.styles";

import Styles from "./Button.styles";

const Button = ({ url, text }) => {
    return (
        <Styles className="col-sm-12">
            <div className="button_wrapper">
                <Link className="button_link" to={url}>
                    <span className="button_link--text">{text}</span>
                    <FontAwesomeIcon icon="angle-double-right" color={theme.colorWhite} />
                </Link>
            </div>
        </Styles>
    );
};

export default Button;
