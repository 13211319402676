import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import Styles from "./Home.styles";
import { theme } from "../../App.styles";
import Navbar from "../../components/navbar/Navbar";
import JumbotronWithTextAndImage from "../../components/jumbotronWithTextAndImage/JumbotronWithTextAndImage";
import Section2 from "../../components/section2/Section2";
import Section3 from "../../components/section3/Section3";
import Section4 from "../../components/section4/Section4";
import Section5 from "../../components/section5/Section5";
import Footer from "../../components/footer/Footer";

const Home = () => {
    const location = useLocation();

    const metaContent = {
        title: "Homepage - Pinecone Software Limited",
        description:
            "Pinecone Software Limited is a next-generation web development service company. We are founded with the motivation of providing a complete cycle of web services.",
        canonical: `https://pineconesoftware.co.uk${location.pathname}`
    };

    return (
        <>
            {/****** Page level head tag content ******/}
            <Helmet>
                <title>{metaContent.title}</title>
                <meta property="og:title" content={metaContent.title} />
                <meta property="og:description" content={metaContent.description} />
                <link rel="canonical" href={metaContent.canonical} />
            </Helmet>

            {/****** Page content ******/}
            <Styles>
                <header>
                    <div className="backgroundWobble"></div>
                    <Navbar />
                    <JumbotronWithTextAndImage />
                </header>
                <main>
                    <Section2 />
                    <Section3 color={theme.colorLogoBlue} />
                    <Section4 />
                    <Section5 color={theme.colorLogoBlue} />
                </main>
                <Footer color={theme.colorLightGray} curveBgColor={theme.colorLogoBlue} />
            </Styles>
        </>
    );
};

export default Home;
