import React from "react";

import { H3, P } from "../../App.styles";
import Styles from "./AboutText.styles";

const AboutText = () => {
    return (
        <Styles>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <H3>Who we are?</H3>
                        <P className="mb-4">
                            Pinecone Software Limited is a next-generation web development service company. We are
                            focused individuals that brainstorm about intelligent strategies and create roadmaps for our
                            customers&apos; digital establishment or transformation.
                        </P>
                        <H3>What do we do?</H3>
                        <P>
                            Our primary focus is to elaborately create a sustainable and maintainable web existence
                            while managing all intermediate web development steps. – In other words, we walk along with
                            you starting from the conception of your web presence to the maintenance of your website(s).
                        </P>
                    </div>
                </div>
            </div>
        </Styles>
    );
};

export default AboutText;
