import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { P, theme, Curve } from "../../App.styles";
import Styles from "./Footer.styles";

import footerLogo from "../../assets/images/pinecone-software-limited-footer-logo.png";
import ukFlag from "../../assets/images/uk-flag-small.png";

const Footer = ({ color, curveBgColor }) => {
    return (
        <Styles color={color}>
            <Curve top color={color} curveBgColor={curveBgColor} />
            <div className="container">
                <div className="row">
                    <div className="col-md-12 footer__logo">
                        <img src={footerLogo} alt="Pinecone Software Limited Large Footer Logo" />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-6 col-lg-6">
                        <P>
                            Pinecone Software Limited is a next-generation web development service company. We are
                            founded with the motivation of providing a complete cycle of web services.
                        </P>
                    </div>
                    <div className="col-sm-5 offset-sm-1 col-xl-3 offset-xl-3">
                        <div className="footer__contact">
                            <ul className="list-unstyled">
                                <li>
                                    <a href="mailto:info@pineconesoftware.co.uk?Subject=Hello!" target="_top">
                                        info@pineconesoftware.co.uk
                                    </a>
                                    <span className="footerIcon">
                                        <FontAwesomeIcon icon="envelope-open" color={theme.colorLogoBlue} />
                                    </span>
                                </li>
                                <li>
                                    <a href="tel:+44 736 785 4707">+44 736 785 4707</a>
                                    <span className="footerIcon">
                                        <FontAwesomeIcon icon="phone" color={theme.colorLogoBlue} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer__bottom">
                            <div className="footer__bottom--copyright">
                                <P className="footer__bottom--copyright--text">
                                    <small>
                                        &copy; Copyright {new Date().getFullYear()} Pinecone Software Limited. All
                                        rights reserved.
                                        <br />
                                        Pinecone Software Limited is a company registered in England and Wales (No
                                        12004735).
                                        <br />
                                        Our registered address is 338a Regents Park Road, Office 3 And 4, London,
                                        England, N3 2LN.
                                    </small>
                                </P>
                            </div>
                            <ul className="footer__bottom--navigation list-unstyled">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="px-2">|</li>
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li className="px-2">|</li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                                <li>
                                    <figure className="footer__bottom--flag">
                                        <img src={ukFlag} alt="UK Flag" />
                                        <span>UK</span>
                                    </figure>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Styles>
    );
};

export default Footer;
