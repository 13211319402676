import styled from "styled-components";

import backgroundBubble from "../../assets/images/background-bubble.png";

const Styles = styled.section`
    background: url(${backgroundBubble}) no-repeat 5% 85%, url(${backgroundBubble}) no-repeat 96% 10%;
    background-color: ${(props) => (props.color ? props.color : "white")};

    .s3 {
        &_images {
            position: relative;

            @media (max-width: 991.98px) {
                width: 85%;
            }

            &--image1 {
                width: 100%;
            }

            &--image2 {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                animation: winner1 1.5s infinite;
            }

            &--image3 {
                position: absolute;
                width: 30%;
                animation: winner2 3s infinite;
            }

            @keyframes winner1 {
                0%,
                20% {
                    opacity: 0;
                }

                10%,
                100% {
                    opacity: 1;
                }
            }

            @keyframes winner2 {
                0% {
                    left: 35%;
                    bottom: 12%;
                }

                33% {
                    left: 30%;
                    bottom: 20%;
                }

                67% {
                    left: 38%;
                    bottom: 23%;
                }

                100% {
                    left: 35%;
                    bottom: 12%;
                }
            }
        }

        &_main {
            margin-bottom: 48px;

            @media (max-width: 768px) {
                text-align: center;
            }
        }

        &_list {
            margin-bottom: 0;

            &--item {
                position: relative;
                margin-left: 60px;

                &:not(:last-of-type) {
                    margin-bottom: 40px;
                }

                &--icon {
                    position: absolute;
                    top: 10px;
                    left: -60px;
                }
            }
        }
    }
`;

export default Styles;
