import styled from "styled-components";

const Styles = styled.section`
    padding: 50px 0 100px;

    @media (max-width: 767.98px) {
        padding: 25px 0 50px;
    }

    .jwot {
        @media (min-width: 992px) {
            text-align: center;
        }
    }
`;

export default Styles;
