import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import GlobalStyles from "./App.globals";
import { theme } from "./App.styles";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import NotFoundPage from "./pages/404/NotFoundPage";
import {
    // Below 3 are used in section4 component
    faRandom,
    faMedal,
    faLayerGroup,
    // Below 6 are used in section5 component
    faSearch,
    faClipboard,
    faPaintBrush,
    faCode,
    faRocket,
    faCog,
    // Below 2 are used in footer component
    faEnvelopeOpen,
    faPhone,
    // Below 1 is used in button component
    faAngleDoubleRight,
    // Below 1 is used in contact component
    faPaperPlane
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faRandom,
    faMedal,
    faLayerGroup,
    faSearch,
    faClipboard,
    faPaintBrush,
    faCode,
    faRocket,
    faCog,
    faEnvelopeOpen,
    faPhone,
    faAngleDoubleRight,
    faPaperPlane
);

const App = () => {
    return (
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="*" element={<NotFoundPage />} />
                        {/* Quick implementation example: */}
                        {/* <Route render={() => { return 404 }} /> */}
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </HelmetProvider>
    );
};

export default App;
